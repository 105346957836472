body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
.topnav {
  background-color: #333;
  overflow: hidden;
  height: fit-content;
  display: flex;
  align-items: center;
}

.topnav a {
  float: left;
  color: #f2f2f2;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
  font-size: 17px;
  line-height: 0.7cm;
}

.topnav a:hover {
  background-color: #ddd;
  color: black;
}

.app-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
}
/*
.sl-bg-canvas-100 {
  background-color: brown;
}
*/
main {
  flex: 1 0 0;
  overflow: hidden;
}